@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "UK Sans";
        src: url("fonts/UKSans-Thin.otf") format("opentype");
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: "UK Sans";
        src: url("fonts/UKSans-Light.otf") format("opentype");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "UK Sans";
        src: url("fonts/UKSans-Regular.otf") format("opentype");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "UK Sans";
        src: url("fonts/UKSans-Medium.otf") format("opentype");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "UK Sans";
        src: url("fonts/UKSans-Bold.otf") format("opentype");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "UK Sans";
        src: url("fonts/UKSans-Heavy.otf") format("opentype");
        font-weight: 800;
        font-style: normal;
    }

    @font-face {
        font-family: "UK Sans";
        src: url("fonts/UKSans-Black.otf") format("opentype");
        font-weight: 900;
        font-style: normal;
    }
}

.ct {
    @apply container px-2 md:px-0 md:mx-auto;
}
